// About.js
import React from 'react';

const About = () => {
  return (
    <div className="max-w-4xl mx-auto px-6">
      <h1 className="text-2xl font-bold mb-8"><span className='text-java-500 font-bold'>NERDERIA.AI</span> YOUR TRUSTED PARTNER FOR AI AND GENAI SOLUTIONS</h1>
      <p className="text-3xl text-java-300 mb-8">
        Empowering your enterprise to harness the full potential of (generative) Artificial Intelligence, <span className='text-java-500 font-bold'>NERDERIA.AI</span>  ensures confidentiality and accessibilty of your proprietary knowledge while you stay focused on your core business. 
      </p>
      <p className="text-3xl text-java-300 mb-8">
        Let us help you to achieve unparalleled growth and success.
      </p>
      <p className="text-java-300 mb-8">&nbsp;</p>
      <h1 className="text-java-300 text-2xl font-bold mb-4">OUR MISSION</h1>
      <p className="mb-8">
      <span className='text-java-500 font-bold'>NERDERIA.AI</span>  is the perfect business partner for small medium enterprises that want to harness the power of AI and increase their productivity and efficiency. Nerderia.ai supplies consultant services and ad hoc GenAI and AI solutions tailored to the customer. Nerderia.ai offers a catalog of off-the-shelf services as well as the capability to build custom solutions. Security and privacy is at the heart of Nerderia.ai services, making sure that your intellectual property and private information is protected.
      </p>

      <h1 className="text-java-300 text-2xl font-bold mb-4">BENEFITS OF OUR SERVICES</h1>
      <p className="mb-8">
      <span className='text-java-500 font-bold'>NERDERIA.AI</span>  services make proprietary knowledge accessible by seamlessly ingesting all data sources and making your custom AI assistant aware of your proprietary knowledge. We assist your business in chore tasks by providing general queries and inference answers based on past information to identify insights not visible to humans. This includes automatic meeting minutes, action items, summaries, and automation of repetitive tasks, ultimately reducing labor costs and increasing data discovery speeds.
      </p>

      <h1 className="text-java-300 text-2xl font-bold mb-4">VALUE PROPOSITION</h1>
      <p className="mb-8">
      <span className='text-java-500 font-bold'>NERDERIA.AI</span>  empowers small and medium enterprises and companies in regulated industries to boost productivity and competitiveness by creating custom, private GenAI solutions that integrate public large language models with tailored repositories for enhanced contextual awareness. This integration delivers significant time savings, efficiency gains, and data-driven insights, ensuring that you deliver customer value faster while protecting your privacy and intellectual property.
      </p>
    </div>
  );
};

export default About;
