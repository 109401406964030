import React from 'react';

const Contact = () => {
  const handleEmailButtonClick = () => {
    const subject = encodeURIComponent("Inquiry via nerderia.ai");
    window.location.href = `mailto:contact@nerderia.ai?subject=${subject}`;
  };

  return (
    <div className="flex flex-col items-center justify-start pt-8">
      <h1 className="text-3xl font-bold mb-4">Contact Us</h1>
      <p className="text-java-300 mb-8 max-w-4xl px-6">
        Welcome to Nerderia, your trusted partner in consultancy services and cutting-edge generative AI products. Whether you are looking to optimize your business processes or integrate AI solutions into your operations, we are here to assist you every step of the way.
      </p>
      <h2 className="text-xl font-bold mb-2">Get In Touch With Our Team!</h2>
      <p className="text-java-300 mb-4">
        For inquiries regarding our services or products, please click the button below to send us an email. Our team will get back to you promptly.
      </p>
      <button
        className="bg-java-700 text-white px-4 py-2 rounded hover:bg-coral-red-600"
        onClick={handleEmailButtonClick}
      >
        Contact Us
      </button>
    </div>
  );
};

export default Contact;
