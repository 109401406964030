import React, { useLayoutEffect } from 'react';
import { initStarfield } from "../main/starfield";

const Home = ({ canvasRef, containerRef, headerRef }) => {
  useLayoutEffect(() => {
    const canvas = canvasRef.current;
    const container = containerRef.current;
    const header = headerRef.current;

    const resizeCanvas = () => {
      if (canvas && container && header) {
        const headerHeight = header.offsetHeight;
        canvas.width = container.clientWidth;
        canvas.height = container.clientHeight - headerHeight;
      }
    };

    // Initialize canvas size
    resizeCanvas();

    // Initialize starfield with containerRef directly
    initStarfield(container);

    // Resize canvas whenever the window is resized
    window.addEventListener('resize', resizeCanvas);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, [canvasRef, containerRef, headerRef]);

  return (
    <div id="logo-container" ref={containerRef} className="flex-grow flex items-center justify-center">
      <div id="logo" className="h-screen items-center justify-center"></div>
      <canvas id="myCanvas" ref={canvasRef} width="1000" height="1000" className="w-full h-full">
        Your browser does not support the HTML5 canvas tag.
      </canvas>
    </div>
  );
};

export default Home;
