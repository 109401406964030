export function initStarfield(container) {

  const canvas = container.querySelector('#myCanvas');
  
  const ctx = canvas.getContext('2d');
  let id;
  var x_off;
  var y_off;
  
  //var min_dist = 0;
  var max_dist = 64;
  var d = 200;
  var n_stars = 500
;
  
  var Point = {
    x: 0,
    y: 0
  };
  
  var elements = [];
  
  function project2d(point, dist) {
    var p = Object.create(Point);
    p.x = Math.round(d * point.x / (dist));
    p.y = Math.round(d * point.y / (dist));
    return p;
  }

  var img = new Image();
  img.src = "images/logo192.png";
  
  var StarElement = {
    p1: null,
    width: 1,
    dist: 0,


    draw: function() {
  
      var p2 = project2d(this.p1, this.dist);
  
      if (p2.x + x_off <= 0 || p2.x + x_off > canvas.width || p2.y + y_off <= 0 || p2.y + y_off > canvas.height) {
        this.dist = max_dist;
      } else {
        
        var percent = (1 - this.dist / max_dist);
  
        ctx.beginPath();
        ctx.strokeStyle = 'hsl(187,79%,' + percent * 100 + '%)';
  
        this.width = percent * 4;
       // ctx.rect(p2.x + x_off, p2.y + y_off, this.width, this.width);
        ctx.arc(p2.x + x_off, p2.y + y_off, this.width/2,0, 2 * Math.PI);

        //ctx.drawImage(img, p2.x + x_off, p2.y + y_off, 10, 10 * img.height / img.width);
  
        ctx.stroke();
        ctx.closePath();
      }
    }
  };
  
  function createElements() {
    for (var i = 0; i < n_stars; i++) {
      var elem = Object.create(StarElement);
      elem.p1 = Object.create(Point);
      elem.p1.x = randomRange(-50, 50);
      elem.p1.y = randomRange(-50, 50);
      elem.dist = randomRange(0, max_dist);
      elements.push(elem);
    }
  }
  
  function update() {
  
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  
    elements.forEach(function(elem, i, arr) {
      elem.dist = elem.dist - 0.2;
      elem.draw();
    });
  
  }
  
  function restart() {

    ctx.strokeStyle = '	hsl(187, 79%, 49%)';
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.lineWidth = 2;
    ctx.moveTo(0, 0);
  
    x_off = canvas.width / 2;
    y_off = canvas.height / 2;
  
    elements = [];
    createElements();
    id = setInterval(update, 30);
  }

  function randomRange(minVal, maxVal) {
    return Math.floor(Math.random() * (maxVal - minVal - 1)) + minVal;
  }
  
  window.addEventListener('resize', () => {
    clearInterval(id);
    restart();
  });

  restart();
}