import './App.scss';
import React, { useRef, useState, useEffect } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Header from "./components/Header/Header";
import Home from "./pages/Home";
import About from "./pages/About";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";
import './corporateidentity.scss';

function App() {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const headerRef = useRef(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    // Check if all refs are initialized
    if (canvasRef.current && containerRef.current && headerRef.current) {
      console.log("All refs are initialized. Setting initialized to true.");
      setInitialized(true);
    }
  }, []);

  return (
    <div className="App h-screen flex flex-col">
      <Router>
        <Header ref={headerRef} />
        <Routes>
          <Route path="/" element={<Home canvasRef={canvasRef} headerRef={headerRef} containerRef={containerRef} />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
