import React, { useState } from "react";
import { Link } from 'react-router-dom';

export default React.forwardRef((props, ref) => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <header ref={ref} className="flex-none w-full flex items-center justify-between py-8 px-4">
      <a href="/">
        <img src="images/logo192.png" alt="logo" width="36px"/>
      </a>
      <nav>
        <section className="MOBILE-MENU flex lg:hidden">
          <div
            className="HAMBURGER-ICON space-y-2"
            onClick={() => setIsNavOpen((prev) => !prev)}
          >
            <span className="block h-0.5 w-8 animate-pulse bg-java-600"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-java-600"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-java-600"></span>
          </div>

          <div className={isNavOpen ? "showMenuNav bg-java-600" : "hideMenuNav bg-java-600"}>
            <div
              className="absolute top-0 right-0 px-8 py-8"
              onClick={() => setIsNavOpen(false)}
            >
              <svg
                className="h-8 w-8 text-java-600"
                viewBox="0 0 24 24"
                fill="none"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
            <ul className="flex flex-col items-center justify-between min-h-[250px]">
              <li className="border-b border-gray-400 my-8 uppercase"><Link to="/" onClick={() => setIsNavOpen(false)}>Home</Link></li>
              <li className="border-b border-gray-400 my-8 uppercase"><Link to="/about" onClick={() => setIsNavOpen(false)}>About</Link></li>
              <li className="border-b border-gray-400 my-8 uppercase"><Link to="/portfolio" onClick={() => setIsNavOpen(false)}>Portfolio</Link></li>
              <li className="border-b border-gray-400 my-8 uppercase"><Link to="/contact" onClick={() => setIsNavOpen(false)}>Contact</Link></li>
            </ul>
          </div>
        </section>
        <ul className="DESKTOP-MENU hidden space-x-8 lg:flex text-java-600">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/portfolio">Portfolio</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </nav>
      <style>{`
      .hideMenuNav {
        display: none;
      }
      .showMenuNav {
        display: block;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
    `}</style>
    </header>
  );
});
